import React from "react";
import logo from "../../Assets/Images/logo.png";
import facebook from "../../Assets/Images/face-icon.png";
import instagram from "../../Assets/Images/insta-icon.png";
import twitter from "../../Assets/Images/twitt-icon.png";
import linkedn from "../../Assets/Images/linkedn-icon.png";

const Footer = () => {
  return (
    <div className="container mt-4">
      <div className="col-lg-12">
        <div className="footers d-flex justify-content-between">
          <div>
            <h3>
              Meet <span class="hquant">Us At</span>
            </h3>
            <p className="">
              Quantzi Infotech Private Limited,
              <br />
              4/410, Venture Park Building, Semi Basement,
              <br /> OMR, Thoraipakkam, Chennai 600097
            </p>
          </div>
          <div>
            <img src={logo} alt="" class="footer-logo mt-2" />
          </div>
        </div>
        <div className="fter-last d-flex justify-content-between">
          <div className="d-flex foot-icons">
            <i class="fa-regular fa-envelope mt-2 me-3"></i>
            <a className="me-5" href="mailto:sales@quantzi.in">
              sales@quantzi.in
            </a>
            <i class="fa-solid fa-phone-volume mt-2 me-3"></i>
            <a className="" href="tel:  +91 73388 68681">
              +91 73388 68681
            </a>
          </div>
          <div className="footer-media">
            <a
              href="https://www.facebook.com/quantzi?mibextid=ZbWKwL"
              target="_blank"
              rel="noreferrer"
            >
              <img src={facebook} alt=".." />
            </a>
            <a
              href="https://www.instagram.com/quantziinfotech?igshid=MzRlODBiNWFlZA%3D%3D"
              target="_blank"
              rel="noreferrer"
              className="ms-4"
            >
              <img src={instagram} alt=".." />
            </a>
            <a
              href="https://twitter.com/i/flow/login?redirect_after_login=%2FQuantziInfotech"
              target="_blank"
              rel="noreferrer"
              className="ms-4"
            >
              <img src={twitter} alt=".." />
            </a>
            <a
              href="https://www.linkedin.com/company/quantziinfotech/?originalSubdomain=in"
              target="_blank"
              rel="noreferrer"
              className="ms-4"
            >
              <img src={linkedn} alt=".." />
            </a>
          </div>
        </div>
        <div className="mt-4 copyrght ">
          <p>© Copyright  2024 Quantzi Infotech. All Rights Reserved.</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
