import React, { useState } from "react";
import questmark from "../../Assets/Images/questionmark.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import projectlogo from "../../Assets/Images/projlogo.png";
import deliverimg from "../../Assets/Images/deliverimg.png";
import ourservicelogo from "../../Assets/Images/ourservicelogo.png";
import processicon1 from "../../Assets/Images/process-1.png";
import processicon2 from "../../Assets/Images/process-2.png";
import processicon3 from "../../Assets/Images/process-3.png";
import processicon4 from "../../Assets/Images/process-4.png";
import processclr1 from "../../Assets/Images/processclr-1.png";
import processclr2 from "../../Assets/Images/processclr-2.png";
import processclr3 from "../../Assets/Images/processclr-3.png";
import processclr4 from "../../Assets/Images/processclr-4.png";
import processlogo from "../../Assets/Images/process-logo.png";
import testimoniallogo from "../../Assets/Images/testimonial-logo.png";
import procesline from "../../Assets/Images/process-line.png";
import teacher from "../../Assets/Images/teacher-testi.png";
import engineer from "../../Assets/Images/engineer-testi.png";
import optune from "../../Assets/Images/optune-testi.png";
import over from "../../Assets/Images/over-testi.png";
import social from "../../Assets/Images/social-testi.png";
import Nammacart from "../../Assets/Images/namma-testi.png";
import whyright from "../../Assets/Images/why-right.png";
import project1 from "../../Assets/Images/project1.png";
import project2 from "../../Assets/Images/project2.png";
import project3 from "../../Assets/Images/project3.png";
import project4 from "../../Assets/Images/project4.png";
import owl1 from "../../Assets/Images/whyowl-1.png";
import owl2 from "../../Assets/Images/whyowl-2.png";
import owl3 from "../../Assets/Images/whyowl-3.png";
import owl4 from "../../Assets/Images/whyowl-4.png";
import owl5 from "../../Assets/Images/whyowl-5.png";
import owl6 from "../../Assets/Images/whyowl-6.png";
import owl7 from "../../Assets/Images/whyowl-7.png";
import owl8 from "../../Assets/Images/whyowl-8.png";
import owl9 from "../../Assets/Images/whyowl-9.png";
import owl10 from "../../Assets/Images/whyowl-10.png";
import owl11 from "../../Assets/Images/whyowl-11.png";
import owl12 from "../../Assets/Images/whyowl-12.png";
import owl13 from "../../Assets/Images/whyowl-13.png";
import owl14 from "../../Assets/Images/whyowl-14.png";
import owl15 from "../../Assets/Images/whyowl-15.png";
import owl16 from "../../Assets/Images/whyowl-16.png";
import owl17 from "../../Assets/Images/whyowl-17.png";
import owl18 from "../../Assets/Images/whyowl-18.png";
import owl19 from "../../Assets/Images/whyowl-19.png";
import owl20 from "../../Assets/Images/whyowl-20.png";
import owl21 from "../../Assets/Images/whyowl-21.png";
import owl22 from "../../Assets/Images/whyowl-22.png";
import owl23 from "../../Assets/Images/whyowl-23.png";
import owl24 from "../../Assets/Images/whyowl-24.png";

import ser1 from "../../Assets/Images/ser1.png";
import ser2 from "../../Assets/Images/ser2.png";
import ser3 from "../../Assets/Images/ser3.png";
import ser4 from "../../Assets/Images/ser4.png";
import ser5 from "../../Assets/Images/ser5.png";
import ser6 from "../../Assets/Images/ser6.png";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import { Link } from "react-router-dom";

const HomeContent = () => {
  const scrollToTop = () => {
    window.scrollTo(60, 60);
  };

  const [Counteron, SetcounterOn] = useState(false);
  const ourProcessTab = [
    { title: "Discover", img: processicon1, img2: processclr1 },
    { title: "Define", img: processclr2, img2: processicon2 },
    { title: "Develop", img: processclr3, img2: processicon3 },
    { title: "Deliver", img: processclr4, img2: processicon4 },
  ];
  const [selectedTab, setSelectedTab] = useState(ourProcessTab[0].title);

  return (
    //  data-aos-duration="1000",
    <div className="">
      <section class="why">
        <div class="container">
          <div class="whyinner">
            <div class="row ">
              <div class="col-lg-6">
                <div
                  class="whyquanzti"
                  data-aos="fade-down"
                  data-aos-duration="1000"
                >
                  <img src={questmark} alt="" />
                  <h1>
                    <span class="hquant">Why Choose QUANTZI </span>
                    <br />
                    For Your App Development Needs?
                  </h1>
                  <p>
                    Over the past <span className="eight">8 years, </span>
                    QUANTZI has been a leader in web and mobile app development,
                    recognized for our unwavering commitment to quality and
                    customer satisfaction. Whether you're a startup or an
                    established business, our tailored solutions have fueled
                    success stories across various industries.
                  </p>
                </div>
                <button
                  class="whybanbtn"
                  onClick={() => {
                    scrollToTop();
                  }}
                  data-aos="flip-up"
                  data-aos-duration="1000"
                >
                  Get In Touch <i class="fa-solid fa-share ms-1"></i>
                </button>
              </div>

              <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1000">
                <div
                  class="whyqbox"
                  // data-aos="fade-up"
                  // data-aos-duration="1000"
                >
                  <img src={whyright} alt=".." />
                </div>
              </div>
            </div>
            <div className="owl">
              <div className="owlback owl-2">
                <OwlCarousel
                  className="owl-theme"
                  loop
                  autoplay={true}
                  items="5"
                  dots={true}
                  center={true}
                  smartSpeed={2000}
                  nav={false}
                  margin={20}
                  autoplayTimeout={2000}
                  responsive={{
                    360: { items: 1 },
                    414: { items: 1 },
                    670: { items: 3 },
                    992: { items: 4 },
                    1200: { items: 4 },
                  }}
                >
                  <div class="whyowlcont d-flex justify-content-around">
                    <span>
                      <img src={owl1} alt=".." />
                    </span>

                    <p>Native Mobile App</p>
                  </div>
                  <div class="whyowlcont  d-flex justify-content-around">
                    <span>
                      <img src={owl2} alt=".." />
                    </span>
                    <p>Cross-Platform Mobile App</p>
                  </div>
                  <div class="whyowlcont  d-flex justify-content-around">
                    <span>
                      <img src={owl3} alt=".." />
                    </span>
                    <p>Hybrid Mobile App</p>
                  </div>
                  <div class="whyowlcont  d-flex justify-content-around">
                    <span>
                      <img src={owl4} alt=".." />
                    </span>
                    <p>Payment Gateway Integration</p>
                  </div>
                  <div class="whyowlcont  d-flex justify-content-around">
                    <span>
                      <img src={owl5} alt=".." />
                    </span>
                    <p>Third-Party APIs Integration</p>
                  </div>
                  <div class="whyowlcont  d-flex justify-content-around">
                    <span>
                      <img src={owl6} alt=".." />
                    </span>
                    <p>Frontend Web Development</p>
                  </div>
                  <div class="whyowlcont  d-flex justify-content-around">
                    <span>
                      <img src={owl7} alt=".." />
                    </span>
                    <p>Backend Web Development</p>
                  </div>
                  <div class="whyowlcont  d-flex justify-content-around">
                    <span>
                      <img src={owl8} alt=".." />
                    </span>
                    <p>Full-Stack Web Development</p>
                  </div>
                  {/* <div class="whyowlcont  d-flex justify-content-around">
                    <span>
                      <img src={} alt=".."/>
                    </span>
                    <p>Patch Management</p>
                  </div> */}
                  {/* <div class="whyowlcont  d-flex justify-content-around">
                    <span>
                      <img src={} alt=".."/>
                    </span>
                    <p>AI $ ML</p>
                  </div> */}
                  <div class="whyowlcont  d-flex justify-content-around">
                    <span>
                      <img src={owl9} alt=".." />
                    </span>
                    <p>Responsive Web Design</p>
                  </div>
                  <div class="whyowlcont  d-flex justify-content-around">
                    <span>
                      <img src={owl10} alt=".." />
                    </span>
                    <p>Custom Web Application</p>
                  </div>
                  <div class="whyowlcont  d-flex justify-content-around">
                    <span>
                      <img src={owl11} alt=".." />
                    </span>
                    <p>CMS Development</p>
                  </div>
                  {/* <div class="whyowlcont  d-flex justify-content-around">
                    <span>
                      <img src={} alt=".."/>
                    </span>
                    <p>Devops</p>
                  </div> */}
                  {/* <div class="whyowlcont  d-flex justify-content-around">
                    <span>
                      <img src={} alt=".."/>
                    </span>
                    <p>Product Sustenance</p>
                  </div> */}
                  <div class="whyowlcont  d-flex justify-content-around">
                    <span>
                      <img src={owl12} alt=".." />
                    </span>
                    <p>E-commerce Development</p>
                  </div>
                  <div class="whyowlcont  d-flex justify-content-around">
                    <span>
                      <img src={owl13} alt=".." />
                    </span>
                    <p>CRM Development</p>
                  </div>
                  <div class="whyowlcont  d-flex justify-content-around">
                    <span>
                      <img src={owl14} alt=".." />
                    </span>
                    <p>HRMS Development</p>
                  </div>
                  <div class="whyowlcont  d-flex justify-content-around">
                    <span>
                      <img src={owl15} alt=".." />
                    </span>
                    <p>ERP Development</p>
                  </div>
                  <div class="whyowlcont  d-flex justify-content-around">
                    <span>
                      <img src={owl16} alt=".." />
                    </span>
                    <p>POS Development</p>
                  </div>
                  <div class="whyowlcont  d-flex justify-content-around">
                    <span>
                      <img src={owl17} alt=".." />
                    </span>
                    <p>Inventory Management Development</p>
                  </div>
                  <div class="whyowlcont  d-flex justify-content-around">
                    <span>
                      <img src={owl18} alt=".." />
                    </span>
                    <p>LMS Development</p>
                  </div>
                  <div class="whyowlcont  d-flex justify-content-around">
                    <span>
                      <img src={owl19} alt=".." />
                    </span>
                    <p>Job Portal Development</p>
                  </div>
                  <div class="whyowlcont  d-flex justify-content-around">
                    <span>
                      <img src={owl20} alt=".." />
                    </span>
                    <p>Hotel Management Development</p>
                  </div>
                  <div class="whyowlcont  d-flex justify-content-around">
                    <span>
                      <img src={owl21} alt=".." />
                    </span>
                    <p>Inventory Management Development</p>
                  </div>
                  <div class="whyowlcont  d-flex justify-content-around">
                    <span>
                      <img src={owl22} alt=".." />
                    </span>
                    <p>Appointment Booking System Development</p>
                  </div>
                  <div class="whyowlcont  d-flex justify-content-around">
                    <span>
                      <img src={owl23} alt=".." />
                    </span>
                    <p>Event Management System Development</p>
                  </div>
                  <div class="whyowlcont  d-flex justify-content-around">
                    <span>
                      <img src={owl24} alt=".." />
                    </span>
                    <p>Travel Booking Portal Development</p>
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="ourproj">
        <div class="container">
          <div class="ourproject ">
            <div class="projheadlogo1">
              <img src={projectlogo} alt=".." />
            </div>
            <h1>
              Our <span class="hquant">Case Studies</span>
            </h1>
          </div>
          <div class="projectbox">
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <div className="project-con pe-4">
                  <div
                    class="projectimg mt-5 "
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                  >
                    <img src={project1} alt=".." />
                  </div>
                  <div
                    class="proj-box-cont pt-3 "
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    {/* <Link to="https://quantzi.in/dalmaf" target="_blank"> */}
                    <h4>
                      Dalmaf
                      {/*  <i class="fa-solid fa-arrow-up ms-2 "></i> */}
                    </h4>
                    {/* </Link> */}
                    <p>
                      Quantzi Infotech played a pivotal role in designing and
                      developing web and mobile applications for Dalmaf, along
                      with a comprehensive CRM-like portal. Leveraging our
                      expertise, we crafted intuitive interfaces, streamlined
                      operations, and ensured seamless integration. Our tailored
                      portal aligns with Dalmaf's specific requirements,
                      enhancing client management and operational efficiency.
                      Through our partnership, Dalmaf now delivers unparalleled
                      support globally.
                    </p>
                    <h5>Services</h5>
                    <div
                      class="services d-flex mt-3"
                      // data-aos="zoom-in"
                      // data-aos-duration="1000"
                    >
                      <div className="d-flex">
                        <i class="fa-regular fa-circle-check"></i>
                        <p>Mobile App </p>
                      </div>
                      <div className="d-flex">
                        <i class="fa-regular fa-circle-check"></i>
                        <p>Web App </p>
                      </div>
                      <div className="d-flex">
                        <i class="fa-regular fa-circle-check"></i>
                        <p>Admin Portal </p>
                      </div>
                    </div>
                    <h5>Tech Stack</h5>
                    <div class="tech-stack d-flex mt-3">
                      <p>React.js </p>
                      <p>React Native</p>
                      <p>Node.js</p>
                      <p>MongoDB</p>
                      <p className="tech-last">AWS</p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-2"></div> */}
              <div class="col-lg-6 col-md-6">
                <div className="project-con ps-4">
                  <div
                    class="projectimg mt-5"
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                  >
                    <img src={project2} alt=".." />
                  </div>
                  <div
                    class="proj-box-cont pt-3"
                    data-aos="fade-down"
                    data-aos-duration="1000"
                  >
                    {/* <Link to="https://quantzi.in/engineerspg" target="_blank"> */}
                    <h4>
                      Engineer's Pg
                      {/* <i class="fa-solid fa-arrow-up ms-2 "></i> */}
                    </h4>
                    {/* </Link> */}
                    <p>
                      Quantzi Infotech proudly showcases its expertise in mobile
                      app development through our collaboration with Engineers
                      PG. By crafting a customized mobile application for
                      Engineers PG, we streamlined resident activities and
                      enhanced convenience and satisfaction. This project
                      highlights our commitment to delivering tailored solutions
                      that meet our clients' specific needs and contribute to
                      their success.
                    </p>
                    <h5>Services</h5>
                    <div
                      class="services d-flex mt-3"
                      // data-aos="zoom-in"
                      // data-aos-duration="1000"
                    >
                      <div className="d-flex">
                        <i class="fa-regular fa-circle-check"></i>
                        <p>Mobile App</p>
                      </div>
                      <div className="d-flex">
                        <i class="fa-regular fa-circle-check"></i>
                        <p>Dynamic Admin Panel</p>
                      </div>
                      <div className="d-flex">
                        <i class="fa-regular fa-circle-check"></i>
                        <p>Website</p>
                      </div>
                    </div>
                    <h5>Tech Stack</h5>
                    <div class="tech-stack d-flex mt-3">
                      <p>React.js </p>
                      <p>React Native</p>
                      <p>Node.js</p>
                      <p>MongoDB</p>
                      <p className="tech-last">AWS</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div className="project-con pe-4">
                  <div
                    class="projectimg mt-5"
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                  >
                    <img src={project3} alt=".." />
                  </div>
                  <div
                    class="proj-box-cont pt-3 "
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    {/* <Link to="https://quantzi.in/nammacart" target="_blank"> */}
                    <h4>
                      Nammacart
                      {/* <i class="fa-solid fa-arrow-up ms-2 "></i> */}
                    </h4>
                    {/* </Link> */}
                    <p>
                      Quantzi Infotech spearheaded the development of a seamless
                      multi-vendor e-commerce platform for Nammacart, an
                      ambitious venture aiming to revolutionize local vendor
                      marketplaces. Leveraging a microservice architecture with
                      the MERN stack, we designed and developed mobile and web
                      apps for both users and sellers, along with a robust admin
                      panel for app owners. Our solution empowers local vendors
                      and enhances the user experience.
                    </p>
                    <h5>Services</h5>
                    <div
                      class="services d-flex mt-3"
                      // data-aos="zoom-in"
                      // data-aos-duration="1000"
                    >
                      <div className="d-flex">
                        <i class="fa-regular fa-circle-check"></i>
                        <p>User Mobile App</p>
                      </div>
                      <div className="d-flex">
                        <i class="fa-regular fa-circle-check"></i>
                        <p>Seller Mobile App </p>
                      </div>
                      <div className="d-flex">
                        <i class="fa-regular fa-circle-check"></i>
                        <p>Web App For User & Seller</p>
                      </div>
                      <div className="d-flex">
                        <i class="fa-regular fa-circle-check"></i>
                        <p>Dynamic Admin Panel </p>
                      </div>
                    </div>
                    <h5>Tech Stack</h5>
                    <div class="tech-stack d-flex mt-3">
                      <p>React.js </p>
                      <p>React Native</p>
                      <p>Node.js</p>
                      <p>MongoDB</p>
                      <p className="tech-last">AWS</p>
                      <p className="tech-last">Microservice Architecture</p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-2"></div> */}
              <div class="col-lg-6 col-md-6">
                <div className="project-con ps-4">
                  <div
                    class="projectimg mt-5"
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                  >
                    <img src={project4} alt=".." />
                  </div>{" "}
                  <div
                    class="proj-box-cont pt-3"
                    data-aos="fade-down"
                    data-aos-duration="1000"
                  >
                    {/* <Link
                      to="https://quantzi.in/my-native-foods"
                      target="_blank"
                    > */}
                    <h4>
                      My Native Foods
                      {/* <i class="fa-solid fa-arrow-up ms-2 "></i> */}
                    </h4>
                    {/* </Link> */}
                    <p>
                      Quantzi Infotech partnered with My Native Foods to
                      modernize its retail operations, addressing diverse needs
                      from POS management to e-commerce integration. We
                      engineered an integrated platform, combining POS,
                      e-commerce, and ERP functionalities, streamlining
                      operations efficiently. Our strategic consultation guided
                      My Native Foods to leverage innovative technology
                      solutions, resulting in improved efficiency and enhanced
                      customer experiences.
                    </p>
                    <h5>Services</h5>
                    <div class="services d-flex mt-3">
                      <div className="d-flex">
                        <i class="fa-regular fa-circle-check"></i>
                        <p>Mobile App </p>
                      </div>
                      <div className="d-flex">
                        <i class="fa-regular fa-circle-check"></i>
                        <p>ERP </p>
                      </div>
                      <div className="d-flex">
                        <i class="fa-regular fa-circle-check"></i>
                        <p>POS </p>
                      </div>
                      <div className="d-flex">
                        <i class="fa-regular fa-circle-check"></i>
                        <p>Dynamic Admin Panel </p>
                      </div>
                    </div>
                    <h5>Tech Stack</h5>
                    <div class="tech-stack d-flex mt-3">
                      <p>React.js </p>
                      <p>React Native</p>
                      <p>Node.js</p>
                      <p>MongoDB</p>
                      <p className="tech-last">AWS</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="letsstart-down mt-4">
              <div
                className="row justify-content-center
            "
              >
                <div
                  className="col-lg-4 col-md-4 col-4"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <div className="let-sec1">
                    {/* <p className="lets-para1">500+</p>
                  <p className="lets-para2">Finished Project</p> */}
                    <ScrollTrigger
                      onEnter={() => SetcounterOn(true)}
                      onExit={() => SetcounterOn(false)}
                    >
                      <p className="lets-para1">
                        {Counteron && (
                          <CountUp start={0} end={500} duration={2} delay={0} />
                        )}
                        +
                      </p>
                    </ScrollTrigger>
                    <p className="lets-para2">Finished Project</p>
                  </div>
                </div>
                {/*  */}

                {/*  */}
                <div
                  className="col-lg-4 col-md-4 col-4"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <div className="let-sec1">
                    <ScrollTrigger
                      onEnter={() => SetcounterOn(true)}
                      onExit={() => SetcounterOn(false)}
                    >
                      <p className="lets-para1">
                        {Counteron && (
                          <CountUp start={0} end={340} duration={2} delay={0} />
                        )}
                        +
                      </p>
                    </ScrollTrigger>
                    <p className="lets-para2">Happy Clients</p>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-4 col-4"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <div className="let-sec3">
                    <ScrollTrigger
                      onEnter={() => SetcounterOn(true)}
                      onExit={() => SetcounterOn(false)}
                    >
                      <p className="lets-para1">
                        {Counteron && (
                          <CountUp start={0} end={25} duration={2} delay={0} />
                        )}
                        +
                      </p>
                    </ScrollTrigger>
                    <p className="lets-para2">Skilled Experts</p>
                  </div>
                </div>
                {/* <div className="col-lg-3">
                {" "}
                <div className="lets-sec-last">
                  <p className="lets-para1">20+</p>
                  <p className="lets-para2">Industries Serviced</p>
                </div>
              </div> */}
              </div>
            </div>
            {/* <div className="d-flex justify-content-center">
              <Link to="https://www.quantzi.in/our-works" target="_blank">
                <button
                  className="view-all "
                  data-aos="flip-down"
                  data-aos-duration="1000"
                >
                  View All Projects<i class="fa-solid fa-share ms-2"></i>
                </button>
              </Link>
            </div> */}
          </div>
        </div>
      </section>
      <section className="deliver">
        <div className="container">
          <div className="row">
            <div>
              <div className="col-lg-6 col-12">
                <div className="deli-exce-left" data-aos="fade-down">
                  <h1>
                    {/* Looking for Mobile App or Web App Development Services? */}
                    Create a Mobile App or Web App For Your Business
                  </h1>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <button
                  class="deliverbtn"
                  onClick={() => {
                    scrollToTop();
                  }}
                  data-aos="flip-left"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="1000"
                >
                  Get a Free Consultation <i class="fa-solid fa-share ms-1"></i>
                </button>
              </div>
              <div
                className="col-lg-6 col-12"

                // data-aos-duration="1000"
              >
                <img src={deliverimg} alt=".." className="deli-exc-img" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="ourservice">
        <div className="container">
          <div className="ourservicehead">
            <div class="ourservice-head-logo">
              <img src={ourservicelogo} alt=".." />
            </div>

            <h1>
              Our <span class="hquant">Services</span>
            </h1>
          </div>
          <div className="ourservicedown ">
            <div className="row">
              {/* <div className="servicesubs mt-5">
                <h2>Mobile App :</h2>
              </div> */}
              <div className="ourservice-flex">
                <div className="col-lg-4 col-md-6">
                  <div className="d-flex service-topic-first flex-column">
                    <span className="first-service">
                      <div>
                        <img src={ser1} alt=".." />
                      </div>
                      <h5>Native Mobile App Development</h5>
                    </span>
                    {/* <div
                    className="service-para "
                    data-aos="fade-up"
                    data-aos-anchor-placement="bottom-bottom"
                    data-aos-duration="1500"
                  >
                    <p className="mt-3 mb-0">
                      Elevate user engagement with Quantzi Infotech's UI/UX
                      design solutions, delivering intuitive interfaces and
                      seamless experiences tailored to your brand and audience.
                    </p>
                  </div> */}
                    {/* <a
                    className="more-detail"
                    href="https://quantzi.in/uiux"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Details <i class="fa-solid fa-arrow-up ms-2 "></i>
                  </a> */}
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="d-flex service-topic-first flex-column">
                    <span className="first-service">
                      <div>
                        <img src={ser2} alt=".." />
                      </div>
                      <h5>Cross-Platform Mobile App Development</h5>
                    </span>
                    {/* <div
                    className="service-para "
                    data-aos="flip-up"
                    data-aos-duration="1000"
                  >
                    <p className="mt-3 mb-0">
                      Transform your ideas into reality with Quantzi Infotech's
                      custom mobile app development solutions, leveraging the
                      latest technologies for immersive digital experiences.
                    </p>
                  </div> */}
                    {/* <a
                    className="more-detail"
                    href="https://quantzi.in/mob-application-development"
                    target="_blank"
                    rel="noreferrer"
                  >
                    More Details <i class="fa-solid fa-arrow-up ms-2 "></i>
                  </a> */}
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="d-flex service-topic-first flex-column">
                    <span className="first-service">
                      <div>
                        <img src={ser3} alt=".." />
                      </div>
                      <h5>Hybrid Mobile App Development</h5>
                    </span>
                    {/* <div
                    className="service-para "
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-bottom"
                    data-aos-duration="1500"
                  >
                    <p className="mt-3 mb-0">
                      Drive innovation with Quantzi Infotech's web app
                      development services, creating dynamic and responsive
                      applications that enhance user interaction and
                      productivity.
                    </p>
                  </div> */}
                    {/* <a
                    className="more-detail"
                    href="https://quantzi.in/web-application-development"
                    target="_blank"
                    rel="noreferrer"
                  >
                    More Details <i class="fa-solid fa-arrow-up ms-2 "></i>
                  </a> */}
                  </div>
                </div>
              </div>
              {/* <div className="servicesubs mt-5">
                <h2>Web App :</h2>
              </div> */}
              <div className="ourservice-flex">
                <div className="col-lg-4 col-md-6">
                  <div className="d-flex service-topic-first flex-column">
                    <span className="first-service">
                      <div>
                        <img src={ser4} alt=".." />
                      </div>
                      <h5>
                        Full-Stack
                        <br /> Development
                      </h5>
                    </span>
                    {/* <div
                    className="service-para"
                    data-aos="fade-down"
                    data-aos-duration="1000"
                  >
                    <p className="mt-3 mb-0">
                      Boost sales and conversions with Quantzi Infotech's
                      e-commerce solutions, providing scalable and user-friendly
                      platforms for online businesses to thrive.
                    </p>
                  </div> */}
                    {/* <a
                    className="more-detail"
                    href="https://quantzi.in/e-com-website-development"
                    target="_blank"
                    rel="noreferrer"
                  >
                    More Details <i class="fa-solid fa-arrow-up ms-2 "></i>
                  </a> */}
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 ">
                  <div className="d-flex service-topic-first flex-column">
                    <span className="first-service">
                      <div>
                        <img src={ser5} alt=".." />
                      </div>
                      <h5>Responsive Web App development</h5>
                    </span>
                    {/* <div
                    className="service-para"
                    data-aos="flip-up"
                    data-aos-duration="1000"
                  >
                    <p className="mt-3 mb-0">
                      Ensure flawless performance and user satisfaction with
                      Quantzi Infotech's comprehensive QA services, covering
                      rigorous testing, optimization, and bug tracking for
                      robust software solutions.
                    </p>
                  </div> */}
                    {/* <a
                    className="more-detail"
                    href="https://quantzi.in/software-testing-and-qa-services"
                    target="_blank"
                    rel="noreferrer"
                  >
                    More Details <i class="fa-solid fa-arrow-up ms-2 "></i>
                  </a> */}
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="d-flex service-topic-first flex-column">
                    <span className="first-service">
                      <div>
                        <img src={ser6} alt=".." />
                      </div>
                      <h5>
                        CRM, ERP, HRMS <br />
                        Development
                      </h5>
                    </span>
                    {/* <div
                    className="service-para"
                    data-aos="fade-down"
                    data-aos-duration="1000"
                  >
                    <p className="mt-3 mb-0">
                      Our MVP Development service specializes in crafting
                      Minimum Viable Products customized to your business's
                      distinct requirements. We ensure swift market validation
                      and cost-effective product launches.
                    </p>
                  </div> */}
                    {/* <a
                    className="more-detail"
                    href="https://quantzi.in/mvp-development"
                    target="_blank"
                    rel="noreferrer"
                  >
                    More Details <i class="fa-solid fa-arrow-up ms-2 "></i>
                  </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="ourprocess">
        <div className="container">
          <div className="ourproc-head">
            <div class="ourproc-head-logo">
              <img src={processlogo} alt=".." />
            </div>
            <div className="d-flex justify-content-between our-process">
              <h1>
                Our
                <span class="hquant"> Approach </span>
              </h1>
              <p>Driving Business Excellence through a Robust Process</p>
            </div>
          </div>
          <div className="ourprocess-down">
            <div className="row">
              <div
                className="col-lg-4 "
                data-aos="zoom-in"
                data-aos-duration="1000"
              >
                <div className="process-response">
                  <div className="process-right ms-5 ">
                    <img src={procesline} alt=".." className="line" />
                    {ourProcessTab.map((item, index) => {
                      return (
                        <div
                          className="d-flex proc-rghtbox "
                          onClick={() => {
                            setSelectedTab(item.title);
                            console.log("clicked", item.title);
                          }}
                          key={index}
                        >
                          {selectedTab === item.title ? (
                            <img src={item.img} alt=".." />
                          ) : (
                            <img src={item.img2} alt=".." />
                          )}

                          <p
                            className={
                              selectedTab === item.title
                                ? "tabparaclr"
                                : "tabparanoclr"
                            }
                          >
                            {item.title}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="col-lg-8" data-aos="fade-up">
                {selectedTab === ourProcessTab[0].title && (
                  <div className="process-left ms-5">
                    <h5>Our Commitment to Innovation at QUANTZI INFOTECH:</h5>
                    <p>
                      We believe in putting you at the heart of our process.
                      That's why we start by closely collaborating with you,
                      capturing your requirements, and uncovering critical
                      insights. <br />
                    </p>
                    <p>
                      By combining your insights with the latest market research
                      and data analytics, we craft solutions tailored to your
                      unique needs. This customer-focused approach ensures that
                      our innovative solutions align perfectly with your
                      specific goals.
                    </p>
                    <div className="bullet-icon">
                      <ul className="ps-0 mt-4">
                        <div className="row">
                          <div className="col-lg-6">
                            <li className="d-flex ">
                              <i class="fa-regular fa-circle-dot mt-1 me-3"></i>
                              <p>Software product Engineering Service</p>
                            </li>
                            <li className="d-flex ">
                              <i class="fa-regular fa-circle-dot mt-1 me-3"></i>
                              <p>Digital Transformation</p>
                            </li>
                            <li className="d-flex ">
                              <i class="fa-regular fa-circle-dot mt-1 me-3"></i>
                              <p>Digital Solution</p>
                            </li>
                          </div>
                          <div className="col-lg-6">
                            <li className="d-flex ">
                              <i class="fa-regular fa-circle-dot mt-1 me-3"></i>
                              <p>Customer Experience (UX / UI)</p>
                            </li>
                            <li className="d-flex ">
                              <i class="fa-regular fa-circle-dot mt-1 me-3"></i>
                              <p>Managed Service</p>
                            </li>
                            <li className="d-flex ">
                              <i class="fa-regular fa-circle-dot mt-1 me-3"></i>
                              <p>Recruiting Solution's</p>
                            </li>
                          </div>
                        </div>
                      </ul>

                      {/* <i class="fa-regular fa-circle-dot mt-1 me-3"></i>
                  <p>Problem Exploration</p>
                  <i class="fa-regular fa-circle-dot  mt-1 me-3"></i>
                  <p>Problem Exploration</p> */}
                    </div>
                  </div>
                )}
                {/*  */}
                {selectedTab === ourProcessTab[1].title && (
                  <div className="process-left ms-5">
                    <h5>Every Successful Project with QUANTZI INFOTECH:</h5>
                    <p>
                      At our software development company, we understand the
                      importance of a well-structured plan. We begin by
                      translating insights gathered from our discovery phase
                      into clear project goals and requirements.
                      <br />
                    </p>
                    <p>
                      Delving deeper, we define user personas and map out their
                      journey. Our design approach prioritizes the end user,
                      ensuring that the solutions we develop resonate with your
                      target audience.
                    </p>
                    <div className="bullet-icon">
                      <ul className="ps-0 mt-4">
                        <div className="row">
                          <div className="col-lg-6">
                            <li className="d-flex ">
                              <i class="fa-regular fa-circle-dot mt-1 me-3"></i>
                              <p>Innovation</p>
                            </li>
                            <li className="d-flex ">
                              <i class="fa-regular fa-circle-dot mt-1 me-3"></i>
                              <p>Create seamless multi-channel interactions</p>
                            </li>
                            <li className="d-flex ">
                              <i class="fa-regular fa-circle-dot mt-1 me-3"></i>
                              <p>Digital Operational Excellence</p>
                            </li>
                          </div>
                          <div className="col-lg-6">
                            <li className="d-flex ">
                              <i class="fa-regular fa-circle-dot mt-1 me-3"></i>
                              <p>Be Data-driven</p>
                            </li>
                            <li className="d-flex ">
                              <i class="fa-regular fa-circle-dot mt-1 me-3"></i>
                              <p>Customer Centric</p>
                            </li>
                          </div>
                        </div>
                      </ul>

                      {/* <i class="fa-regular fa-circle-dot mt-1 me-3"></i>
                  <p>Problem Exploration</p>
                  <i class="fa-regular fa-circle-dot  mt-1 me-3"></i>
                  <p>Problem Exploration</p> */}
                    </div>
                  </div>
                )}
                {/*  */}
                {selectedTab === ourProcessTab[2].title && (
                  <div className="process-left ms-5">
                    <h5>Your Vision:</h5>
                    <p>
                      At our software development company, we believe in putting
                      you first. Our innovative process begins by closely
                      collaborating with you to capture your requirements and
                      uncover critical insights.
                      <br />
                    </p>
                    <p>
                      By combining these insights with the latest market
                      research and data analytics, we create solutions tailored
                      to your unique needs. This customer-focused approach
                      ensures that our innovative solutions align perfectly with
                      your specific goals.
                    </p>
                    <div className="bullet-icon">
                      <ul className="ps-0 mt-4">
                        <div className="row">
                          <div className="col-lg-6">
                            <li className="d-flex ">
                              <i class="fa-regular fa-circle-dot mt-1 me-3"></i>
                              <p>Content Strategy & Campaigns</p>
                            </li>
                            <li className="d-flex ">
                              <i class="fa-regular fa-circle-dot mt-1 me-3"></i>
                              <p>Pre-Migration Assessments</p>
                            </li>
                            <li className="d-flex ">
                              <i class="fa-regular fa-circle-dot mt-1 me-3"></i>
                              <p>Customer Journey Mapping</p>
                            </li>
                            <li className="d-flex ">
                              <i class="fa-regular fa-circle-dot mt-1 me-3"></i>
                              <p>Digital Consulting</p>
                            </li>
                          </div>
                          <div className="col-lg-6">
                            <li className="d-flex ">
                              <i class="fa-regular fa-circle-dot mt-1 me-3"></i>
                              <p>Omni-Channel Strategy</p>
                            </li>
                            <li className="d-flex ">
                              <i class="fa-regular fa-circle-dot mt-1 me-3"></i>
                              <p>Personalization</p>
                            </li>
                            <li className="d-flex ">
                              <i class="fa-regular fa-circle-dot mt-1 me-3"></i>
                              <p>Strategy</p>
                            </li>
                            <li className="d-flex ">
                              <i class="fa-regular fa-circle-dot mt-1 me-3"></i>
                              <p>Application Audits</p>
                            </li>
                          </div>
                        </div>
                      </ul>

                      {/* <i class="fa-regular fa-circle-dot mt-1 me-3"></i>
                  <p>Problem Exploration</p>
                  <i class="fa-regular fa-circle-dot  mt-1 me-3"></i>
                  <p>Problem Exploration</p> */}
                    </div>
                  </div>
                )}
                {selectedTab === ourProcessTab[3].title && (
                  <div className="process-left ms-5">
                    <h5>Deliver Excellence:</h5>
                    <p>
                      At our software development company, we prioritize quality
                      at every step. No project receives our stamp of approval
                      without undergoing rigorous testing. We conduct extensive
                      user and usability testing to ensure that our design
                      solutions meet user needs effectively.
                      <br />
                    </p>
                    <p>
                      Our dedicated quality assurance team meticulously reviews
                      every aspect of the project, ensuring that it adheres to
                      our set standards and is aligned with the product's end
                      goals.
                    </p>
                    <div className="bullet-icon">
                      <ul className="ps-0 mt-4">
                        <div className="row">
                          <div className="col-lg-6">
                            <li className="d-flex ">
                              <i class="fa-regular fa-circle-dot mt-1 me-3"></i>
                              <p>Rigorous Testing</p>
                            </li>
                            <li className="d-flex ">
                              <i class="fa-regular fa-circle-dot mt-1 me-3"></i>
                              <p>Quality Assurance</p>
                            </li>
                            <li className="d-flex ">
                              <i class="fa-regular fa-circle-dot mt-1 me-3"></i>
                              <p>Innovation-Driven</p>
                            </li>
                          </div>
                          <div className="col-lg-6">
                            <li className="d-flex ">
                              <i class="fa-regular fa-circle-dot mt-1 me-3"></i>
                              <p>Transparent Communication</p>
                            </li>
                            <li className="d-flex ">
                              <i class="fa-regular fa-circle-dot mt-1 me-3"></i>
                              <p>On-Time Delivery</p>
                            </li>
                            <li className="d-flex ">
                              <i class="fa-regular fa-circle-dot mt-1 me-3"></i>
                              <p>Post-Deployment Support</p>
                            </li>
                          </div>
                        </div>
                      </ul>

                      {/* <i class="fa-regular fa-circle-dot mt-1 me-3"></i>
                  <p>Problem Exploration</p>
                  <i class="fa-regular fa-circle-dot  mt-1 me-3"></i>
                  <p>Problem Exploration</p> */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="testimonial ">
        <div className="container">
          <div className="testimo-img">
            <div class="testi-head-logo">
              <img src={testimoniallogo} alt=".." />
            </div>
            <div className="testi-head d-flex justify-content-between">
              <h1>What People Say About Us</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="owl">
                <div className="owlback">
                  <OwlCarousel
                    className="owl-theme"
                    loop
                    autoplay={false}
                    items="2"
                    dots={false}
                    center={true}
                    smartSpeed={2000}
                    nav={true}
                    margin={20}
                    autoplayTimeout={5000}
                    responsive={{
                      360: { items: 1 },
                      414: { items: 1 },
                      670: { items: 1 },
                      992: { items: 2 },
                      1200: { items: 2 },
                    }}
                  >
                    {/* <div class="whyowl"> */}
                    <div class="testicont">
                      <p className="testi-content">
                        Quantzi Infotech has been an invaluable partner,
                        delivering exceptional results with their expertise and
                        dedication. Their innovative solutions and commitment to
                        excellence have made them an integral part of our team.
                        We're excited to continue our partnership for future
                        projects!
                      </p>
                      <div className="testi-person d-flex">
                        <div className="ms-2 person-img-sec">
                          <h6>Ravindranath Shankar</h6>
                          <p>Founder - Optune Technologies</p>
                        </div>
                        <div>
                          <img
                            src={optune}
                            alt=".."
                            className="testi-logo mt-2"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="testicont">
                      <p className="testi-content">
                        We partnered with Quantzi Infotech to design our website
                        from just a vague idea. Their team transformed our
                        vision into a well-designed site, making the process
                        smooth and easy. They've become our go-to solution for
                        all our tech needs.
                      </p>
                      <div className="testi-person d-flex">
                        <div className="ms-2 person-img-sec">
                          <h6>Sankari Sudhar</h6>
                          <p>Founder - Overqualified Housewives</p>
                        </div>
                        <div>
                          {" "}
                          <img
                            src={over}
                            alt=".."
                            className="testi-logo mt-2"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="testicont">
                      <p className="testi-content">
                        Thank you and your team for the amazing updates to the
                        NammaCart Platform! Your hard work has given our app a
                        fresh look and boosted its efficiency, impressing our
                        clients and users. We're excited to continue working
                        together on future enhancements!
                      </p>
                      <div className="testi-person d-flex">
                        <div className="ms-2 person-img-sec">
                          <h6>Arun Peter</h6>
                          <p>CEO - Namma Cart</p>
                        </div>
                        <div>
                          {" "}
                          <img
                            src={Nammacart}
                            alt=".."
                            className="testi-logo mt-2"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="testicont">
                      <p className="testi-content">
                        Quantzi Tech's work on the Engineer's PG app was truly
                        impressive. Their team's expertise, professionalism, and
                        dedication shone through, delivering a product that
                        exceeded our expectations with its intuitive interface
                        and innovative features. I highly recommend Quantzi Tech
                        for anyone needing top-notch app development services!
                      </p>
                      <div className="testi-person d-flex">
                        <div className="ms-2 person-img-sec">
                          <h6>Surendiran</h6>
                          <p>Founder - Engineers PG</p>
                        </div>
                        <div>
                          {" "}
                          <img
                            src={engineer}
                            alt=".."
                            className="testi-logo mt-2"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="testicont">
                      <p
                        className="testi-content
                      "
                      >
                        We've partnered closely with Quantzi for over 7 years
                        now. Their friendly yet professional team has
                        consistently delivered, making them our top choice among
                        many. Special thanks to Peter, Ram, and Michael for
                        their on-time deliveries and prompt responses.
                      </p>
                      <div className="testi-person d-flex">
                        <div className="ms-2 person-img-sec">
                          <h6>Sudharsanan Ganapathy</h6>
                          <p>Founder & CEO - The Social Eagle</p>
                        </div>
                        <div>
                          {" "}
                          <img
                            src={social}
                            alt=".."
                            className="testi-logo mt-2"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="testicont">
                      <p className="testi-content">
                        Our website, Teacher Magi, was created by the Quantzi
                        team. From initial discussions to setting up the site,
                        they were consistently available and patient with our
                        requirements. The site is easy to navigate, and they
                        remain accessible whenever we need assistance.
                      </p>
                      <div className="testi-person d-flex">
                        <div className="ms-2 person-img-sec">
                          <h6>Sudha ravi</h6>
                          <p>Teacher,Educator&Writer-Teacher Magi</p>
                        </div>
                        <div>
                          <img
                            src={teacher}
                            alt=".."
                            className="testi-logo mt-2"
                          />
                        </div>
                      </div>
                    </div>

                    {/* </div> */}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="lets mt-5">
        <div className="container">
          <div
            className="lets-head "
            data-aos="flip-right"
            data-aos-duration="1000"
          >
            <div>
              <h1>
                Let's <br />
                Kickstart Your Project! 
              </h1>
              <p>
                Unleash the potential: Let's discuss how we can supercharge your
                great brand with added value.
              </p>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <button
              class="whybanbtn"
              onClick={() => {
                scrollToTop();
              }}
            >
              Get a Free Consultation
              <i class="fa-solid fa-share ms-2"></i>
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomeContent;
