import React, { useEffect, useState } from "react";
import axios from "axios";
import ban1 from "../../Assets/Images/ban1.png";
import ban2 from "../../Assets/Images/ban2.png";
import ban3 from "../../Assets/Images/ban3.png";
import ban4 from "../../Assets/Images/ban4.png";
import ban5 from "../../Assets/Images/ban5.png";
import ban6 from "../../Assets/Images/ban6.png";
import ban7 from "../../Assets/Images/ban7.png";
import ban8 from "../../Assets/Images/ban8.png";
// import ban9 from "../../Assets/Images/ban5.png";
// import ban10 from "../../Assets/Images/ban6.png";
// import ban11 from "../../Assets/Images/ban7.png";
// import ban12 from "../../Assets/Images/ban8.png";
import bannerback from "../../Assets/Images/banbulb.png";
// import whitespace from "../../Assets/Images/whitespace.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import AOS from "aos";
import "aos/dist/aos.css";
import CustomModal from "../../Common/CustomModal";

const HomeBanner = () => {
  const scrollToTop = () => {
    window.scrollTo(160, 160);
  };
  // const Apiurl =
  //   "https://script.google.com/macros/s/AKfycbzEanJMLL8yQsHa06q5PhlH5Hbj4Xb1lneCje7RleX5ER6Q1sXIKPhBupek-HOJ5jav/exec";
  useEffect(() => {
    AOS.init({
      //  data-aos-duration="1000",
    });
  }, []);

  const [value, setValue] = useState({});
  const [validation, setValidation] = useState({});
  // const [thankYouModal, setThankYouModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  function isEmail(email) {
    return !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
  }

  const CheckValidation = () => {
    let Status = true,
      validdateData = {};
    if (!value.Fname || !value.Fname?.length) {
      validdateData.Fname = "First Name is Required";
      Status = false;
    } else if (!isNaN(value.Fname)) {
      validdateData.Fname = "First Name is invalid";
      Status = false;
    } else if (value.Fname === undefined) {
      validdateData.Fname = "First Name is Required";
      Status = false;
    } else if (value.Fname === "") {
      validdateData.Fname = "First Name is Required";
      Status = false;
    }
    //  else if (value.Fname.length <= 1) {
    //   validdateData.Fname = "First Name must be maximum 2 letters";
    //   Status = false;
    // }

    // if (!value.Lname || !value.Lname?.length) {
    //   validdateData.Lname = "Last Name is Required";
    //   Status = false;
    // } else if (!isNaN(value.Lname)) {
    //   validdateData.Lname = "Last Name is invalid";
    //   Status = false;
    // } else if (value.Lname === undefined) {
    //   validdateData.Lname = "Last Name is Required";
    //   Status = false;
    // } else if (value.Lname === "") {
    //   validdateData.Lname = "Last Name is Required";
    //   Status = false;
    // }
    // else if (value.Lname.length <= 1) {
    //   validdateData.Lname = "Last Name must be maximum 2 letters";
    //   Status = false;
    // }

    if (!value.mobileNumber === undefined) {
      validdateData.mobileNumber = "Mobile Number is Required";
      Status = false;
    } else if (!value.mobileNumber?.toString().length) {
      validdateData.mobileNumber = "Mobile Number is Required";
      Status = false;
    } else if (value.mobileNumber.toString().length !== 10) {
      validdateData.mobileNumber = "Mobile Number must be 10 digits";
      Status = false;
    }

    if (!value.email?.trim()?.length) {
      validdateData.email = "Email is Required";
      Status = false;
    } else if (value.email?.includes(" ")) {
      validdateData.email = "Email cannot include spaces";
      Status = false;
    } else if (value.email && isEmail(value.email)) {
      validdateData.email = "Email is Invalid";
      Status = false;
    }

    if (!value.companyName) {
      validdateData.companyName = "Company Name is Required";
      Status = false;
    }
    if (!value.service) {
      validdateData.service = "Service is Required";
      Status = false;
    }
    setValidation({ ...validation, ...validdateData });
    return Status;
  };
  // console.log(validation);
  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    // console.log(validation);
    let inputvalue = {
      Name: value.Fname,
      // LastName: value.Lname,
      MobileNumber: value.mobileNumber,
      Email: value.email,
      CompanyName: value.companyName,
      Service: value.service,

      // CompanyWebsite: value.companyWebsite,
      // PositionInCompany: value.positionInCompany,

      Date: new Date(),
      Location: window.location.href,
    };
    console.log(inputvalue);
    let formdata = new FormData();

    for (let key in inputvalue) {
      formdata.append(key, inputvalue[key]);
    }
    console.log([...formdata]);
    if (CheckValidation()) {
      // console.log(" no error");
      setOpen(true);
      try {
        await axios
          .post(
            "https://script.google.com/macros/s/AKfycbyZj4oPvAHFJuj8gmy4wkVlw6MSMEBbHoiryElRSHRSo6m0G_PQRnn5KDvCotE5EXSu/exec",
            formdata
          )
          .then((response) => {
            window.location.reload();
            setValue({});
          });
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const setData = (e, key) => {
    setValue({ ...value, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };

  return (
    <>
      <section class="banner">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="bannerrght">
                <img src={bannerback} alt=".." className="banbulb" />
                {/* <small className="bannersml">
                  Empowering Businesses Digitally
                </small> */}
                <h2>
                  Focus on your business,
                  <br /> We'll handle the <br />
                  technical part
                </h2>
                <p>
                  At Quantzi, we go beyond development, offering comprehensive
                  technical consultation to guide you every step of the way.
                </p>

                <button
                  class="banbtn"
                  onClick={() => {
                    scrollToTop();
                  }}
                >
                  Let's Get Started
                  <i class="fa-solid fa-hand-pointer ms-2"></i>
                </button>
              </div>
            </div>
            <div class="col-lg-6">
              <div className="banner-rght ms-5 ">
                <h3>Request a FREE Consultation !</h3>
                <form onSubmit={(e) => handleSubmit(e)}>
                  <div className="d-flex gap-4 justify-content-between mt-4">
                    <div style={{ width: "100%" }}>
                      <input
                        type="text"
                        name="Fname"
                        placeholder="Name*"
                        className="me-2 w-100"
                        onChange={(e) => {
                          setData(e.target.value, "Fname");
                        }}
                      />
                      {validation.Fname && (
                        <span className="validationmsg small">
                          {validation.Fname}
                        </span>
                      )}
                    </div>
                    {/* <div style={{ width: "50%" }}>
                      <input
                        type="text"
                        name="Lname"
                        placeholder="Last Name*"
                        className="me-2 w-100"
                        onChange={(e) => {
                          setData(e.target.value, "Lname");
                        }}
                      />
                      {validation.Lname && (
                        <span className="validationmsg small">
                          {validation.Lname}
                        </span>
                      )}
                    </div> */}
                  </div>

                  <div className="second-part-input">
                    <input
                      type="number"
                      name="mobileNumber"
                      placeholder="Mobile Number*"
                      className="mt-3"
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        setData(e.target.value, "mobileNumber");
                      }}
                    />
                    {validation.mobileNumber && (
                      <span className="validationmsg small">
                        {validation.mobileNumber}
                      </span>
                    )}
                    <input
                      type="text"
                      name="email"
                      placeholder="Business Email Address*"
                      className="mt-3"
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        setData(e.target.value, "email");
                      }}
                    />
                    {validation.email && (
                      <span className="validationmsg small">
                        {validation.email}
                      </span>
                    )}
                    <input
                      type="text"
                      name="companyName"
                      placeholder="Company Name*"
                      className="mt-3"
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        setData(e.target.value, "companyName");
                      }}
                    />
                    {validation.companyName && (
                      <span className="validationmsg small">
                        {validation.companyName}
                      </span>
                    )}
                    {/* <select
                      name="service"
                      style={{ width: "100%" }}
                      className="mt-3 dropdown"
                      onChange={(e) => {
                        setData(e.target.value, "service");
                      }}
                    >
                      <option>Select Service</option>

                      <option value="mobileapp">Mobile App</option>
                      <option value="webapp">Web App</option>
                      <option value="Ecommerce">E-commerce Website</option>
                    </select>
                    {validation.service && (
                      <span className="validationmsg small">
                        {validation.service}
                      </span>
                    )} */}
                  </div>
                  <button
                    className={`sign-up mt-3  ${loading ? "sending" : ""}`}
                  >
                    {loading ? <div>Submit</div> : "Submit"}
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="owl-1 mt-5">
            <OwlCarousel
              className="owl-theme"
              loop
              autoplay={true}
              items="2"
              dots={true}
              center={true}
              smartSpeed={2000}
              nav={false}
              margin={20}
              autoplayTimeout={2000}
              responsive={{
                360: { items: 4 },
                414: { items: 4 },
                670: { items: 3 },
                992: { items: 4 },
                1200: { items: 7 },
              }}
            >
              <div className="banner-owl">
                <img src={ban1} alt=".." />
              </div>
              <div className="banner-owl">
                <img src={ban2} alt=".." />
              </div>
              <div className="banner-owl">
                <img src={ban3} alt=".." />
              </div>
              <div className="banner-owl">
                <img src={ban4} alt=".." />
              </div>
              <div className="banner-owl">
                <img src={ban5} alt=".." />
              </div>
              <div className="banner-owl">
                <img src={ban6} alt=".." />
              </div>
              <div className="banner-owl">
                <img src={ban7} alt=".." />
              </div>
              <div className="banner-owl">
                <img src={ban8} alt=".." />
              </div>
            </OwlCarousel>
          </div>
        </div>
      </section>
      <CustomModal
        open={open}
        onClickOutside={() => {
          setOpen(false);
        }}
      >
        <div className="modal-content">
          <div className="header">
            <div className="text-center ">
              <i class="fa-solid fa-circle-check green fs-1"></i>
            </div>
          </div>
          <div className="successData">
            <p className="text-center  mb-0 mt-2">
              Your request has been submitted successfully
              <span></span>
            </p>
            <div className="share-btn text-center mt-2">
              <button
                onClick={() => {
                  setOpen(false);

                  window.location.reload();
                }}
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default HomeBanner;
