import "./App.css";
import "./Css/Style.css";
import "./Css/Responsive.css";
import { Main } from "./Main";
function App() {
  return (
    <div>
      <Main />
    </div>
  );
}

export default App;
