import React from "react";
import logo from "../../Assets/Images/logo.png";

const Navbar = () => {
  const scrollToTop = () => {
    window.scrollTo(70, 70);
  };
  return (
    <div class="header">
      <nav class="navbar navbar-expand-lg ">
        <div class="container p-4">
          <img src={logo} alt="" class="logoimg" />
          <button
            class="navbar-toggler"
            type="button"
            // data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="" id="navbarSupportedContent">
            <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <button
                  type="button"
                  class="nav-button"
                  onClick={() => {
                    scrollToTop();
                  }}
                >
                  Get In Touch
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
